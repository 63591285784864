/* 
 * cq-dialog is added by chartIQ at the end of html. Hence, body is taken as parent (position="relativ") to position pop up.
 * Chartiq css make cq-dialog as `position:fixed`
 * App is fixed height of 800px, Hence centering it to 300px 
 * z-index of HeaderWidget is 1100, DialogContent is 1200, Hence cq-dialog is 1210
*/
cq-dialog {
	z-index: 1210;
	margin: 0 auto;	
	top: 300px;
}

/* Ways to put our icon and override css */
/* .op-chart-icons {
  display: inline-block;
}

#chart-modal-dialog .op-chart-icons {
  display: none;
}

.op-hide-collapse {
  display: none;
}

#chart-modal-dialog .op-hide-collapse {
  display: inline;
} */
