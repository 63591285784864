/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* body {
  font-size: 12px !important;
} */
html,
body {
  min-width: 550px;
}

body.optionsplay {
  min-width: 1366px;
}

body.body-background-light {
  background-color: #fafafa;
}

body.body-background-dark {
  background-color: #353434;
}

body.body-background-ml-blue {
  background-color: #1c5495;
}

body.body-background-ml-dark {
  background-color: #000000;
}

body.body-background-obsidian {
  background-color: #000000;
}

.trades-container .company-name {
  font-size: 10px;
}

.border-danger-left {
  border-left: 10px solid red;
}

.border-success-left {
  border-left: 10px solid green;
}

.trade-detail {
  border: 1px solid gray;
  border-radius: 10px !important;
  padding-left: 10px !important;
}

.trade-detail-lable span {
  color: gray;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.strategy-icon {
  background: url(../../shared/assets/images/strategies-icons.png) no-repeat top left;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.stg-CustomStrategy {
  background-position: 0 0;
}

.stg-LongCallButterfly {
  background-position: 0 -40px;
}

.stg-LongCallCalendar {
  background-position: 0 -80px;
}

.stg-LongCallCondor {
  background-position: 0 -120px;
}

.stg-LongCallDiagonal {
  background-position: 0 -160px;
}

.stg-LongCallRatio1x2 {
  background-position: 0 -200px;
}

.stg-LongCallRatio2x3 {
  background-position: 0 -240px;
}

.stg-LongCallVertical {
  background-position: 0 -280px;
}

.stg-LongCall {
  background-position: 0 -320px;
}

.stg-LongCollar {
  background-position: 0 -360px;
}

.stg-LongCoveredCall {
  background-position: 0 -400px;
}

.stg-LongDoubleDiagonal {
  background-position: 0 -440px;
}

.stg-LongFinancedVertical {
  background-position: 0 -480px;
}

.stg-LongIronButterfly {
  background-position: 0 -520px;
}

.stg-LongIronCondor {
  background-position: 0 -560px;
}

.stg-LongProtectivePut {
  background-position: 0 -600px;
}

.stg-LongPutButterfly {
  background-position: 0 -640px;
}

.stg-LongPutCalendar {
  background-position: 0 -680px;
}

.stg-LongPutCondor {
  background-position: 0 -720px;
}

.stg-LongPutDiagonal {
  background-position: 0 -760px;
}

.stg-LongPutRatio1x2 {
  background-position: 0 -800px;
}

.stg-LongPutRatio2x3 {
  background-position: 0 -840px;
}

.stg-LongPutVertical {
  background-position: 0 -880px;
}

.stg-LongPut {
  background-position: 0 -920px;
}

.stg-LongStock {
  background-position: 0 -960px;
}

.stg-LongStraddle {
  background-position: 0 -1000px;
}

.stg-LongStrangle {
  background-position: 0 -1040px;
}

.stg-LongSyntheticStock {
  background-position: 0 -1080px;
}

.stg-LongVerticalSpreadSpread {
  background-position: 0 -1120px;
}

.stg-LongRepairTrade {
  background-position: 0 -200px;
}

.stg-ShortCallButterfly {
  background-position: 0 -1160px;
}

.stg-ShortCallCalendar {
  background-position: 0 -1200px;
}

.stg-ShortCallCondor {
  background-position: 0 -1240px;
}

.stg-ShortCallDiagonal {
  background-position: 0 -1280px;
}

.stg-ShortCallRatio1x2 {
  background-position: 0 -1320px;
}

.stg-ShortCallRatio2x3 {
  background-position: 0 -1360px;
}

.stg-ShortCallVertical {
  background-position: 0 -1400px;
}

.stg-ShortCall {
  background-position: 0 -1440px;
}

.stg-ShortCollar {
  background-position: 0 -1480px;
}

.stg-ShortCoveredCall {
  background-position: 0 -1520px;
}

.stg-ShortDoubleDiagonal {
  background-position: 0 -1560px;
}

.stg-ShortFinancedVertical {
  background-position: 0 -1600px;
}

.stg-ShortIronButterfly {
  background-position: 0 -1640px;
}

.stg-ShortIronCondor {
  background-position: 0 -1680px;
}

.stg-ShortPutButterfly {
  background-position: 0 -1720px;
}

.stg-ShortPutCalendar {
  background-position: 0 -1760px;
}

.stg-ShortPutCondor {
  background-position: 0 -1800px;
}

.stg-ShortPutDiagonal {
  background-position: 0 -1840px;
}

.stg-ShortPutRatio1x2 {
  background-position: 0 -1880px;
}

.stg-ShortPutRatio2x3 {
  background-position: 0 -1920px;
}

.stg-ShortPutVertical {
  background-position: 0 -1960px;
}

.stg-ShortPut {
  background-position: -40px 0;
}

.stg-ShortStock {
  background-position: -40px -40px;
}

.stg-ShortStraddle {
  background-position: -40px -80px;
}

.stg-ShortStrangle {
  background-position: -40px -120px;
}

.stg-ShortSyntheticStock {
  background-position: -40px -160px;
}

.stg-ShortVerticalSpreadSpread {
  background-position: -40px -200px;
}

.stg-LongRiskReversal {
  background-position: 0 -1080px;
}

.stg-ShortRiskReversal {
  background-position: -40px -160px;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 5px;
}

.MuiFormHelperText-root.Mui-error {
  margin: 2px;
}

.span-error {
  color: #ff0000;
}

.add-text-with-input-left {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><text x="0" y="22" style="font: bold 13px Arial;">$</text></svg>')
    no-repeat;
  height: 30px;
  padding-left: 10px;
  padding-top: 5px;
  border-bottom: 10px solid;
  border-width: 0;
  border-bottom: 1px solid;
  outline: none;
  border-bottom-color: darkgray;
}

.textInput {
  height: 30px;
  padding-top: 5px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom-width: 1px;
  outline: none;
  border-bottom-color: darkgray;
}

.add-text-with-input-right {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="129" height="30"><text x="100" y="22" style="font: bold 12px Arial;">%</text></svg>')
    no-repeat;
  height: 30px;
  padding-right: 15px;
  padding-top: 5px;
  border-bottom: 10px solid;
  border-width: 0;
  border-bottom: 1px solid;
  outline: none;
  border-bottom-color: darkgray;
}

.disable-mouse-pointer {
  cursor: pointer !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  position: 'absolute';
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0;
  /* background-color: red; */
}

::-webkit-calendar-picker-indicator:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
}

.lbButton {
  display: inline-block;
  background: #7ac143;
  border: #7ac143;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
}

.linkToMethodology {
  color: white;
}

/*NOTE: Below class is used to hide the stroke-width(outline) around the text in trading-range-simulator*/
.highcharts-text-outline {
  stroke-width: 0 !important;
}
